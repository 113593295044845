#root {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.app {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.view_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ntv_bga_color_a { background-color: rgba(0, 160, 0, 0.25); }
.ntv_bga_color_b { background-color: rgba(0, 160, 0, 0.5); }

.ntv_bg_color_a { background-color: #00a000; }
.ntv_bg_color_b { background-color: #1fe301; }

.crs_pointer { cursor: pointer; }
.crs_default { cursor: default; }

.fnt_wht_a { color: white; }

.fnt_ntv_a { color: #d40e32; }

.fnt_blk_a { color: #888888; }

.txt_bold { font-weight: bold; }

.txt_12 { font-size: 12pt; }
.txt_14 { font-size: 14pt; }
.txt_16 { font-size: 16pt; }
.txt_20 { font-size: 20pt; }
.txt_36 { font-size: 36pt; }

.button_a {
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.dropdown_dsp_none { display: none; }
.dropdown_dsp_show { display: flex; }

/* CUSTOM CSS HOMEPAGE */

.nav_homepage { display: flex; }

.brand_homepage {
  right: -20px;
  height: auto;
}

.txt_brand {
  text-shadow: 4px 4px 1px rgba(150, 150, 150, 1);
  font-size: 34pt;
}

.tutorial_cnt { margin: 50px 50px; }

#tutorial_cnt_inline { max-width: 640px; }

.cnt_home_contact { padding: 20px; }

/* CUSTOM CSS DASHBOARD */

.cnt_profile { display: flex; flex-direction: row; }

.cnt_profile_point { margin : 0 0 0 20px; }

.cnt_exchange_form { margin : 0 0 0 20px; }

/* ANDROID VIEW ON 420px */

@media only screen and (max-width : 420px) {

  /* CUSTOM CSS HOMEPAGE */

  .brand_homepage {
    right: -300px;
    height: 100%;
  }

  .txt_brand { font-size: 20pt; }

  .nav_homepage { display: none; }

  .tutorial_cnt { margin: 50px 0; }

  #tutorial_cnt_inline { max-width: 320px; }

  .cnt_home_contact { padding: 20px 0 0 0; }

  /* CUSTOM CSS DASHBOARD */

  .cnt_profile { flex-direction: column; }

  .cnt_profile_point { margin : 20px 0 0 0; }

  .cnt_exchange_form { margin : 10px 0 0 0; }

}
